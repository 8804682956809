import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import SectionHeading from "../components/sectionheading"
import "../utils/about.css"
import books from "../utils/books.json"
import { AiOutlineLink } from "react-icons/ai"

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    console.log(data)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About Me" />
        <SectionHeading title="About me" />
        <div
          className="ctn"
          style={{
            display: "flex",
          }}
        >
          <Img
            fixed={data.fileName.childImageSharp.fixed}
            style={{
              width: "40%",
            }}
          />
          <div
            style={{
              width: "60%",
              fontSize: "0.90rem",
              paddingLeft: "0.75rem",
            }}
          >
            <ul
              style={{
                listStyle: "none",
              }}
            >
              <li>
                Hi, my name is <span>{"Daksh Gautam 👋"}</span>
              </li>
              <li>
                Graduated with <span>Masters in Computer Science 🎓</span> from{" "}
                <span>University of Florida 🐊</span>
              </li>
              <li>
                {"Follow me on "}
                <span>
                  <a href="https://twitter.com/dakshgautam6">Twitter</a>
                </span>
                {" and "}
                <span>
                  <a href="https://medium.com/@dakshcoder">Medium</a>
                </span>
              </li>
              <li>
                Want to know more about me ? Checkout my{" "}
                <span>
                  <a href="https://www.dakshgautam.com/" target="_blank">
                    portfolio
                  </a>
                </span>
                .
              </li>
            </ul>
          </div>
        </div>
        <SectionHeading title="Books I'm reading" />
        <div>
          <ul>
            {books.map(book => (
              <li
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
                key={book.name}
              >
                {book.name}
                <a
                  className="book-link"
                  href={book.link}
                  style={{
                    boxShadow: "none",
                    paddingLeft: "2px",
                  }}
                  target="_blank"
                >
                  <AiOutlineLink />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        themeColor
      }
    }
    fileName: file(relativePath: { eq: "my_pic.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
